<template>
  <v-select
    :label="textLabel"
    placeholder="seleccione una sucursal"
    :loading="loading"
    :items="items"
    item-text="second_name"
    item-value="id"
    :rules="onlyRequired"
    v-bind:value="selected"
    v-on:input="$emit('input', $event)"
    :return-object="returnObj ? true : false"
    ref="auto"
  ></v-select>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  props: {
    textLabel: { type: String, required:false, default: "Sucursal" },
    value: [Number, Array, String, Object],
    reset: { type: Boolean },
    returnObj: { type: Boolean },
  },
  data: () => ({
    selected: undefined,
    items: [],
    loading: false,
    onlyRequired: [rules.required],
  }),
  mounted() {
    this.loadItems();
  },
  methods: {
    loadItems() {
      this.loading = true;
      requests.get("api/catalogs/companies").then((response) => {
        if (response.status == 200) {
          this.items = response.data;
          if (this.items.length == 1) {
            this.selected = this.items[0];
          }
        } else {
          console.log(response);
        }
        this.loading = false;
      });
    },
  },
  watch: {
    reset: function () {
      this.$refs.auto.reset();
    },
    value: function (val) {
      this.selected = val;
    },
    selected: function (val) {
      this.$emit("value", val);
    },
  },
};
</script>
